<template>
  <v-container fluid>
    <v-card elevation="6" outlined>

      <v-card-text class="text-center">
        <v-row align-content="center" class="mx-auto" justify="center">
          <v-col class="mx-auto" cols="12" md="6" sm="10">
            <v-form lazy-validation ref="form">
              <v-row>
                <v-col class="pb-0" cols="12">
                  <v-text-field :label="this.$t('customers.lang_nameCustomerGroup')" :rules="[v => !!v]" class="ma-0"
                                dense outlined v-model="CustGroupName"/>
                </v-col>

                <v-col class="pt-0" cols="12">
                  <v-select :items="this.types" :label="this.$t('customers.lang_CustomerGroupType')" :rules="[v => !!v]"
                            class="ma-0"
                            dense hide-selected item-text="name" item-value="value" outlined
                            v-model="type"/>
                </v-col>
                <v-col cols="12" align-self="center">
                  <v-checkbox v-model="enableTaxOverriding" dense hide-details
                              outlined :label="$t('erp.lang_enableTaxOverriding')" class="mx-1" />
                </v-col>
                <v-col cols="12" >
                  <v-text-field v-if="enableTaxOverriding" dense type="number" @input="taxInput"
                                outlined  :label="$t('erp.lang_tax_value')" class="mx-1"
                                v-model="taxRate"/>
                </v-col>
                <v-col class="pt-0">
                  <v-btn :disabled="loading" :loading="loading" @click="create" block class="ma-0"
                         color="primary" large>{{ this.$t('generic.lang_create') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import {ENDPOINTS} from "./../../../config";
import {Events} from "./../../../plugins/events"

export default {
  name: "CustomerGroupCreateComponent",
  data: () => {
    return {
      ENDPOINTS,
      type: 0,
      CustGroupName: "",
      loading: false,
      enableTaxOverriding:false,
      taxRate:0,
    }
  },
  computed: {
    types: function () {
      return [
        {
          value: 1,
          name: this.$t('customers.lang_custGroupDiffPrices')
        },
        {
          value: 2,
          name: this.$t('customers.lang_custGroupDiscount2Waregroup')
        }
      ]
    }
  },
  methods: {
    taxInput() {
      if (Number(this.taxRate) < 0) {
        this.taxRate=0;
      }
    },
    create() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMERGROUPS.CREATE, {
        CustGroupName: this.CustGroupName,
        CustGroupType: this.type,
        enableTaxOverriding: this.enableTaxOverriding,
        taxRate: this.taxRate,
      }).then((res) => {

        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('generic.lang_ActionSuccessful')
          });
          this.$router.push('/customer/custGroups');
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
          });
        }

      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>